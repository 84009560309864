import { lazy } from "react";
import Loadable from "ui-component/Loadable";

import SalesLayOut from "views/Sales-Sections/SalesLayOut";
const Purchase = Loadable(
  lazy(() => import("../views/Purchase-Sections/purchase/purchase"))
);
// const PruchaseReturn = Loadable(lazy(() => import('../views/purchase/purchaseReturn/PruchaseReturn')));
const PurchaseReturn = Loadable(
  lazy(() => import("../views/Purchase-Sections/purchaseReturn/purchaseReturn"))
);
const PurchaseImport = Loadable(
  lazy(() =>
    import("../views/Purchase-Sections/purchase Import/purchaseImport")
  )
);


const PurchaseRoute = {
  path: "/purchase",
  // element: <MinimalLayout />,
  // element: <SalesLayOut />,
  element: <SalesLayOut />,
  children: [
    {
      path: "purchasebill",
      element: <Purchase />,
    },
    {
      path: "purchasebillReturn",
      element: <PurchaseReturn />,
    },
    {
      path: "purchaseImport",
      element: <PurchaseImport />,
    },
  ],
};

export default PurchaseRoute;
