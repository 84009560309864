import * as React from 'react';
import PropTypes from 'prop-types';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

function LoaderDrop({ start }) {
  return (
    <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={start}>
      <CircularProgress color="success" />
    </Backdrop>
  );
}
LoaderDrop.propTypes = {
  start: PropTypes.bool.isRequired,
};

export default LoaderDrop;
