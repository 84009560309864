import { useSelector, useDispatch } from "react-redux";
import { useRef, useEffect } from "react";

import {
  updateRateAndTotal,
  updateQtyAndTotal,
  deleteItemFromTableData,
  addItemToTableData,
  updateRateAndTotalTaxUtilityEnabled,
} from "views/Sales-Sections/salesReturn/slice/tableDataSlice";
import {
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  IconButton,
  Box,
  Paper,
  TextField,
  Autocomplete,
  createFilterOptions,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTheme } from "@mui/material/styles";
import AddItemFileds from "./AddItemFileds";
import { useState } from "react";
import dayjs from "dayjs";
import { redirectUrl } from "config";
import { baseUrl } from "config";
import axios from "axios";

const getProduct = "/getItemNames";

const styles = {
  tableContainer: {
    height: "45vh",
    overflow: "hidden",
    marginTop: 1,
    background: "#fff",
  },
  tableBody: {
    maxHeight: "300px",
    overflowY: "auto",
  },
};
const filterOptions = createFilterOptions({
  matchFrom: "any",
  limit: 200,
});
const TableSection = () => {
  const UpperRef = useRef(null);
  const [dropDownValue, setDropDown] = useState(null);
  const tableRef = useRef(null);
  const tableContainerRef = useRef();
  const theme = useTheme();
  const dispatch = useDispatch();
  const [upper, setUpperRef] = useState(false);
  const [allProducts, setAllProducts] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [currProduct, setCurrProduct] = useState(null);
  const [modalTyp, setModalTyp] = useState(null);
  const [batchWise, setBatchWise] = useState("");
  const [expiry, setExpiry] = useState("");
  const taxUtilitiesListData = useSelector(
    (state) => state.common.taxUtilitiesListData
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(baseUrl + getProduct, {
          withCredentials: true,
        });
        setAllProducts(response.data.result);

        const values = response.data.batchExpiry.split("-");
        setBatchWise(values[0]);
        setExpiry(values[1]);
      } catch (error) {
        if (
          (error.response && error.response.status === 403) ||
          error.response.status === 401
        ) {
          // Handle the error or navigate to another page as needed
          redirectUrl();
        } else {
          // Handle other types of errors
          console.error("API request failed:", error.message);
        }
      }
    };

    fetchData();
  }, []);

  const tableData = useSelector((state) => state.tableData);
  // useEffect(() => {
  //     // Scroll to the bottom of the table body when new rows are added
  //     setTimeout(() => {
  //         if (tableRef.current) {
  //             tableRef.current.lastChild.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
  //         }
  //     }, 0);
  // }, [tableData]);

  useEffect(() => {
    if (upper) {
      setTimeout(() => {
        console.log(UpperRef.current);

        UpperRef.current?.focus();
        setUpperRef(false);
      });
      setTimeout(() => {
        UpperRef.current?.focus();
      }, 200);
    }
  }, [upper]);
  useEffect(() => {
    // Scroll to the bottom of the table body when new rows are added
    setTimeout(() => {
      if (tableContainerRef.current) {
        tableContainerRef.current.scrollTop =
          tableContainerRef.current.scrollHeight;
      }
    }, 0);
  }, [tableData.length]);

  // Update quantity value
  const handleQtyChange = (index, newValue) => {
    let qty;
    const TaxEnabled = taxUtilitiesListData?.find(
      (data) => data.Typ === "SALES_RETURN"
    );
    if (newValue > 0) {
      qty = newValue;
    } else {
      qty = 0;
    }
    // Calculate the new total based on the updated qty
    const newItem = {
      ...tableData[index],
      qty: qty,
      rate: qty * tableData[index].Rate1,
      taxAmt:
        TaxEnabled.Nam === "YES"
          ? (qty * tableData[index].Rate1 * tableData[index].Tax1) /
            (100 + tableData[index].Tax1)
          : ((qty * tableData[index].Rate1) / 100) * tableData[index].Tax1,
      total: qty * tableData[index].Rate1,
    };
    // Dispatch the action to update qty and total
    dispatch(
      updateQtyAndTotal({
        index,
        qty: qty,
        total: newItem.total,
        taxAmt: newItem.taxAmt,
        Rate1: newItem.rate,
      })
    );
  };

  // Update rate value
  const handleRateChange = (index, newValue) => {
    // Calculate the new total based on the updated Rate1
    const TaxEnabled = taxUtilitiesListData?.find(
      (data) => data.Typ === "SALES_RETURN"
    );

    if (TaxEnabled.Nam === "YES") {
      const item = tableData[index];
      const newRate1 = newValue;
      const tax = (item.qty * item.Rate1 * item.Tax1) / (100 + item.Tax1);
      // const newTotal = newRate1 * item.qty;

      // Dispatch the action to update Rate1 and total
      dispatch(
        updateRateAndTotalTaxUtilityEnabled({
          index,
          Rate1: newRate1,
          qty: item.qty,
          taxAmt: tax,
        })
      );
    } else {
      const item = tableData[index];
      const newRate1 = newValue;
      const tax = ((item.qty * newRate1) / 100) * item.Tax1;
      // const newTotal = newRate1 * item.qty;

      // Dispatch the action to update Rate1 and total
      dispatch(
        updateRateAndTotal({
          index,
          Rate1: newRate1,
          qty: item.qty,
          taxAmt: tax,
        })
      );
    }
  };
  const handleClickOpen = (typ) => {
    setModalTyp(typ);

    setOpenModal(true);
  };
  // Modal close
  // const handleClose = () => {
  //   setOpenModal(false);
  // };
  // Delete product from table
  const deleteItemFromTable = (itemNo) => {
    dispatch(deleteItemFromTableData({ ItemNo: itemNo }));
  };
  const handleAutocompleteChange = (event, newValue) => {
    setDropDown(newValue);
    const TaxEnabled = taxUtilitiesListData?.find(
      (data) => data.Typ === "SALES_RETURN"
    );
    // item.qty * (item.Rate1 / 100) * item.Tax1
    let newItem;
    if (newValue) {
      // if (TaxEnabled.Nam === "YES") {
      newItem = {
        ...newValue,
        qty: 1,
        taxAmt:
          TaxEnabled.Nam === "YES"
            ? (newValue.Rate1 * newValue.Tax1) / (100 + newValue.Tax1)
            : ((1 * newValue.Rate1) / 100) * newValue.Tax1,
        total:
          TaxEnabled.Nam === "YES"
            ? 1 * newValue.Rate1
            : newValue.taxAmt + newValue.Rate1,
        exp_date: dayjs().format("DD/MM/YYYY"),
      };
      console.log(newItem);

      // } else {
      //   newItem = {
      //     ...newValue,
      //     qty: 1,
      //     taxAmt: ((1 * newValue.Rate1) / 100) * newValue.Tax1,
      //     total: newValue.taxAmt + newValue.Rate1,
      //     exp_date: dayjs().format("DD/MM/YYYY"),
      //   };
      // }

      setCurrProduct(newItem);
      if (newValue.Typ !== newValue.SecUnit) {
        handleClickOpen("multiUnit");
      } else if (batchWise === "YES BATCH") {
        handleClickOpen("batchWise");
      } else {
        // If neither condition is met, add the item to the table data
        dispatch(addItemToTableData(newItem));
      }
    }
  };

  // Clear the Autocomplete value
  // function clearValues() {
  //   setDropDown(null);
  // }

  return (
    <>
      <Box sx={{ px: 1, pt: 0.5 }}>
        <AddItemFileds
          batchWise={batchWise}
          setUpperRef={setUpperRef}
          openModal={openModal}
          currProduct={currProduct}
          modalTyp={modalTyp}
          expiry={expiry}
          setDropDown={setDropDown}
          setModalTyp={setModalTyp}
          setOpenModal={setOpenModal}
          allProducts={allProducts}
          setBatchWise={setBatchWise}
          setAllProducts={setAllProducts}
          setExpiry={setExpiry}
        />
      </Box>

      <Box
        sx={{ background: "#fff", height: "45vh", overflow: "hidden", mt: 1 }}
        className="productTable"
        component={Paper}
        elevation={3}
      >
        <TableContainer sx={{ height: "100%" }} ref={tableContainerRef}>
          <Table
            stickyHeader
            size="small"
            aria-label="a dense table"
            sx={{ textAlign: "center" }}
          >
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    background: `${theme.palette.grey[500]}`,
                    textAlign: "center",
                    color: "#fff",
                    width: "22px",
                  }}
                >
                  Action
                </TableCell>
                <TableCell
                  sx={{
                    background: `${theme.palette.grey[500]}`,
                    textAlign: "center",
                    color: "#fff",
                    width: "22px",
                  }}
                >
                  SiNo
                </TableCell>
                <TableCell
                  sx={{
                    background: `${theme.palette.grey[500]}`,
                    textAlign: "left",
                    color: "#fff",
                    width: "200px",
                  }}
                >
                  Barcode
                </TableCell>
                <TableCell
                  sx={{
                    background: `${theme.palette.grey[500]}`,
                    textAlign: "left",
                    color: "#fff",
                    width: "550px",
                    overflow: "hidden",
                  }}
                >
                  Product Name
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "center",
                    background: `${theme.palette.grey[500]}`,
                    color: "#fff",
                    minWidth: "100px",
                    maxWidth: "100px",
                  }}
                >
                  Qty
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "center",
                    background: `${theme.palette.grey[500]}`,
                    color: "#fff",
                    minWidth: "120px",
                    maxWidth: "120px",
                  }}
                >
                  Rate
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "center",
                    background: `${theme.palette.grey[500]}`,
                    color: "#fff",
                  }}
                >
                  Tax %
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "center",
                    background: `${theme.palette.grey[500]}`,
                    color: "#fff",
                  }}
                >
                  {/* Tax ₹{' '} */}
                  Tax Amt
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "center",
                    background: `${theme.palette.grey[500]}`,
                    color: "#fff",
                  }}
                >
                  Total
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "center",
                    background: `${theme.palette.grey[500]}`,
                    color: "#fff",
                  }}
                >
                  Cost
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "center",
                    background: `${theme.palette.grey[500]}`,
                    color: "#fff",
                  }}
                >
                  Pack
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "center",
                    background: `${theme.palette.grey[500]}`,
                    color: "#fff",
                  }}
                >
                  Unit
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "center",
                    background: `${theme.palette.grey[500]}`,
                    color: "#fff",
                  }}
                >
                  Batch
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "center",
                    background: `${theme.palette.grey[500]}`,
                    color: "#fff",
                  }}
                >
                  Exp Date
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody style={styles.tableBody}>
              {tableData.map((item, index) => {
                return (
                  <TableRow
                    ref={tableRef}
                    sx={{ textAlign: "center" }}
                    key={index}
                  >
                    <TableCell align="center">
                      <IconButton
                        onClick={() => deleteItemFromTable(item.ItemNo)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        minWidth: "50px",
                        maxWidth: "50px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        textAlign: "left",
                      }}
                    >
                      {item.ItemC}
                    </TableCell>
                    <TableCell
                      align="start"
                      sx={{
                        minWidth: "140px",
                        maxWidth: "250px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {item.ItemNameTextField}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        minWidth: "100px",
                        maxWidth: "100px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      <TextField
                        size="small"
                        type="number"
                        value={item.qty}
                        inputProps={{
                          min: 0,
                          inputMode: "numeric",
                          pattern: "[0-9]*",
                        }}
                        onChange={(e) =>
                          handleQtyChange(index, parseFloat(e.target.value))
                        }
                        onFocus={(event) => {
                          event.target.select();
                        }}
                        inputRef={UpperRef}
                      />
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        minWidth: "120px",
                        maxWidth: "120px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      <TextField
                        size="small"
                        type="number"
                        value={item.Rate1}
                        onChange={(e) =>
                          handleRateChange(index, e.target.value)
                        }
                      />
                    </TableCell>
                    <TableCell align="center">{item.Tax1}</TableCell>
                    <TableCell align="center">
                      {item.taxAmt.toFixed(2)}
                    </TableCell>
                    <TableCell align="center">
                      {item.total.toFixed(3)}
                    </TableCell>
                    <TableCell align="center">{item.Cost}</TableCell>
                    <TableCell align="center">{item.Pack}</TableCell>
                    <TableCell align="center">{item.Typ}</TableCell>
                    <TableCell align="center">{item.batch}</TableCell>
                    <TableCell align="center">{item.exp_date}</TableCell>
                  </TableRow>
                );
              })}
              <TableRow ref={tableRef} sx={{ textAlign: "center" }}>
                <TableCell align="center">
                  <IconButton>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
                <TableCell align="center">{/* {index + 1} */}</TableCell>
                <TableCell
                  align="center"
                  sx={{
                    minWidth: "50px",
                    maxWidth: "50px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {/* {item.ItemC} */}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    minWidth: "140px",
                    maxWidth: "210px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  <Autocomplete
                    id="ProductName"
                    value={dropDownValue}
                    onChange={handleAutocompleteChange}
                    options={allProducts}
                    filterOptions={filterOptions}
                    getOptionLabel={(option) =>
                      `${option.ItemC} - ${option.ItemNameTextField}`
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        color="info"
                        label="Product Name"
                        size="small"
                        placeholder="Search barcode or item name"
                        fullWidth
                      />
                    )}
                  />
                  {/* {item.ItemNameTextField} */}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    minWidth: "100px",
                    maxWidth: "100px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  <TextField
                    size="small"
                    type="number"
                    // value={item.qty}
                    inputProps={{
                      min: 0,
                      inputMode: "numeric",
                      pattern: "[0-9]*",
                    }}
                    // onChange={(e) => handleQtyChange(index, parseFloat(e.target.value))}
                    onFocus={(event) => {
                      event.target.select();
                    }}
                  />
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    minWidth: "120px",
                    maxWidth: "120px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  <TextField
                    size="small"
                    type="number"
                    // value={item.Rate1}
                    // onChange={(e) => handleRateChange(index, e.target.value)}
                  />
                </TableCell>
                <TableCell align="center">{/* {item.Tax1} */}</TableCell>
                <TableCell align="center">
                  {/* {item.taxAmt.toFixed(2)} */}
                </TableCell>
                <TableCell align="center">
                  {/* {item.total.toFixed(3)} */}
                </TableCell>
                <TableCell align="center">{/* {item.Cost} */}</TableCell>
                <TableCell align="center">{/* {item.Pack} */}</TableCell>
                <TableCell align="center">{/* {item.Typ} */}</TableCell>
                <TableCell align="center">{/* {item.batch} */}</TableCell>
                <TableCell align="center">{/* {item.exp_date} */}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

export default TableSection;
