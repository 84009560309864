import { createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";

const posHeaderSliceB2C = createSlice({
  name: "posHeader",
  initialState: {
    customer: null,
    contact: 0,
    BatchNo: 0,
    invo: 0,
    date: dayjs(),
    dealer: null,
    outlet: null,
    deliveryDate: dayjs(),
    balance: 0,
    edit: false,
    salesBill: 0,
    user: null,
    creditLimit: 0,
    other: { Name: "" },
    otherMenuItems: [],
    orderNo: "",
    partyWiseRate: false,
  },
  reducers: {
    updateSelected: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    resetState: () => {
      // Reset the state to its initial/default values
      return {
        customer: null,
        contact: 0,
        BatchNo: 0,
        edit: false,
        // invo: 0,
        date: dayjs(),
        deliveryDate: dayjs(),
        // dealer: null,
        // outlet: null,
        balance: 0,
      };
    },
  },
});

export const { updateSelected, resetState } = posHeaderSliceB2C.actions;
export default posHeaderSliceB2C.reducer;
