import React, { lazy, Suspense } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { handleKeyDown } from "views/utilities/enterFunctionForPOS";

const SalesHeader = lazy(() => import("./components/SalesHeader"));
const TableSection = lazy(() => import("./components/TableSection"));
const Footer = lazy(() => import("./components/Footer"));

const SkeletonLoader = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  margin: theme.spacing(1),
  height: "30px", // Adjust the height as needed
}));

const CardWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  color: theme.palette.primary[800],
  minHeight: "90vh",
  maxHeight: "100%",
  overflow: "hidden",
  position: "relative",
  "&:before": {
    content: '""',
    zIndex: 0,
    position: "absolute",
    width: 210,
    height: 210,
    background: `linear-gradient(210.04deg, ${theme.palette.primary.dark} -50.94%, rgba(144, 202, 249, 0) 83.49%)`,
    borderRadius: "50%",
    top: -30,
    right: -100,
  },
  "&:after": {
    content: '""',
    position: "absolute",
    zIndex: -1,
    width: 210,
    height: 210,
    background: `linear-gradient(140.9deg, ${theme.palette.primary.dark} -14.02%, rgba(144, 202, 249, 0) 77.58%)`,
    borderRadius: "50%",
    top: -140,
    right: -60,
  },
}));
function OrderForm() {
  // useEffect(() => {
  //     function getUser() {
  //         axios
  //             .get(baseUrl + '/getUserName')
  //             .then((res) => {
  //                 dispatch(updateSelected({ user: { Name: res.data.Name } }));
  //             })
  //             .catch((error) => {
  //                 if (error.response && error.response.status === 403) {
  //                    redirectUrl()
  //                 }
  //             });
  //     }
  //     getUser();
  // }, []);
  return (
    <CardWrapper onKeyDown={handleKeyDown}>
      <Box sx={{ py: 1, px: 0.5 }}>
        <Suspense fallback={<SkeletonLoader />}>
          <SalesHeader />
        </Suspense>
        <Box sx={{ py: 0.5 }}>
          <Suspense fallback={<SkeletonLoader />}>
            <TableSection />
          </Suspense>
        </Box>
        <Box sx={{ py: 0.5 }}>
          <Suspense fallback={<SkeletonLoader />}>
            <Footer />
          </Suspense>
        </Box>
      </Box>
    </CardWrapper>
  );
}

export default OrderForm;
